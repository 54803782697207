<template>
<Navbar :user="user" />
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="instructor-details-desc">
                <div class="list-group text-start">
                    <h2 class="text-center">{{ course.title }}</h2>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
                        <router-link :to="{ name: 'TrainerAddTest' }" class="btn default-btn">Make Test For This Course</router-link>
                        <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn default-btn">Add Lessons</router-link>
                    </div>
                    <div v-if="course_content.length">
                        <h3 class="text-center">All Chapters </h3>
                        <div class="accordion accordion-flush text-start" id="accordionFlushExample">
                            <div class="accordion-item" v-for="content in course_content" :key="content.id">
                                <h2 class="accordion-header" :id="'flush-heading'+content.id">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+content.id" aria-expanded="false" :aria-controls="'flush-collapse'+content.id">
                                    {{ content.title }}
                                </button>
                                </h2>
                                <div :id="'flush-collapse'+content.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+content.id" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
                                            <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn text-default">
                                                <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                            </router-link>
                                            <button class="btn text-default" @click="deleteChapter(content.id)">
                                                <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>  
                                            </button>
                                        </div>
                                        <ol class="list-group list-group-numbered">
                                            <li class="list-group-item d-flex justify-content-between align-items-start"  v-for="lesson in content.lessons" :key="lesson.id">
                                                <div class="ms-2 me-auto">
                                                    {{ lesson.name }}
                                                </div>
                                                <span class="badge">
                                                    <router-link :to="{ name: 'TrainerLesson', params: {id: lesson.id}}" class="btn text-default">
                                                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                    </router-link>
                                                    <router-link :to="{ name: 'TrainerLessonEdit', params: {id: lesson.id}}" class="btn text-default">
                                                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                                    </router-link>
                                                    <button class="btn text-default" @click="deleteLesson(lesson.id)">
                                                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>  
                                                    </button>
                                                </span>
                                            </li>
                                        </ol>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center" v-else>
                        <h3>No Lesson Has Been Added</h3>
                        <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn default-btn">Add A Lesson</router-link>
                    </div>
                </div> 
                        
            </div>

        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    components: { Navbar,Loading },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            course: {},
            user: {},
            course_content: {}
        }
    },
    methods:{
        getCourseContent(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons/' + id).then(response => {
                this.course_content = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
         deleteLesson(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/course_content/delete/'+ id)
                .then(response => {
                    response.data
                     this.content.lessons = this.content.lessons.filter(lesson => {
                      return lesson.id != id
                    })
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Specialist has been Deleted.',
                'success'
                )
            }
            })
        },
        deleteChapter(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/course_content/delete/'+ id)
                .then(response => {
                    response.data
                     this.content.lessons = this.content.lessons.filter(lesson => {
                      return lesson.id != id
                    })
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Specialist has been Deleted.',
                'success'
                )
            }
            })
        },
        getCourses(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
                this.course = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            })
        },
    },
    created(){
        document.title = `Course Lessons - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getCourses()
        this.getCourseContent() 
    }
}
</script>

<style>
.instructor-details-area .section-title {
	margin-bottom: 0px;
}
</style>